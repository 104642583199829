<template>
  <RightSideBar
    v-if="openModal"
    @close="close('cancel')"
    @submit="handleSubmit"
    submit="Import"
    width="width:500px"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">Import Performance Agreement</p>
    </template>
    <template v-slot:subtitle>
      <div class="w-full mb-5">
        <p>Import performance agreement information</p>
        <p class="my-2">
          Make sure the file is in a XLSX file type/extension and table columns
          are arranged. There is a sample XLSX file below
        </p>
        <a
          class="text-flame underline"
          href="/static/Performance Template Sample.xlsx"
          download
          >Download Sample .xlsx File</a
        >
        <small class="block mt-2"> File must not exceed 3MB in size </small>
      </div>
      <div class="px-5">
        <file-upload supported-files=".xlsx" @input="handleFileUpload" style="max-width: 380px;"/>

      </div>
    </template>
    <div v-if="name !== ''" class="mt-5">
      <divider border-dash />
      <div class="my-5">
        <h6 class="text-romanSilver text-xs uppercase font-semibold">
          {{ percent === "100%" ? "Uploaded" : "Uploading" }}
        </h6>
        <div class="flex mt-5 justify-between">
          <div class="flex">
            <icon icon-name="xlsx" size="m" />
            <div class="ml-2">
              <h4 class="text-darkPurple font-semibold">{{ name }}</h4>
              <span class="text-carrotOrange text-sm"
                >{{ percent === "100%" ? "Done" : "In progress" }}
                <span class="text-romanSilver"
                  >{{ progress
                  }}<span class="text-flame ml-1">{{ percent }}</span></span
                ></span
              >
            </div>
          </div>
          <div
            v-if="percent === '100%'"
            @click="resetValue()"
            class="deleteIcon pt-1 my-auto cursor-pointer"
          >
            <icon icon-name="delete" size="s" class="text-flame m-auto" />
          </div>
        </div>
      </div>
      <divider border-dash />
    </div>


  </RightSideBar>
</template>

<script>
import axios from "axios";
import FileUpload from "@scelloo/cloudenly-ui/src/components/file-upload";
import RightSideBar from "@/components/RightSideBar";

export default {
  components: {
    RightSideBar,
    FileUpload,
    Divider: () => import("@/components/divider"),
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    selectedTemplate: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    year: {
      type: Date,
      default: ''
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_PERFORMANCE_UPLOAD,
      progress: "",
      percent: 0,
      data: "",
      name: "",
      templateId: "",
      timerId: "",
    };
  },
  watch: {
    percent(value) {
      return value;
    },
  },
  methods: {
    close(type) {
      this.resetValue();
      this.$emit("close", type);
    },
    resetValue() {
      this.data = "";
      this.name = "";
      this.progress = "";
      this.percent = "";
    },
    async handleFileUpload(file) {
      this.resetValue();
      const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        this.progress = `${loaded}kb of ${total}kb`;
        this.percent = `${percent}%`;
      };
      this.name = file[0].name;
      const fileExtension = this.name.substr(this.name.lastIndexOf(".") + 1);
      const exceedMaxFileSize = file[0].size > 3145728;
      if (fileExtension !== "xlsx") {
        this.resetValue();
        this.$toasted.error("Only .xlsx files are allowed", { duration: 6000 });
      } else if (exceedMaxFileSize) {
        this.resetValue();
        this.$toasted.error("File size exceeds the limit of 3 MB", {
          duration: 6000,
        });
      } else {
        const formData = new FormData();
        formData.append("file", file[0]);
        try {
          const { data } = await axios.post(this.url, formData, {
            headers: {
              "Content-type": "multipart/form-data",
            },
            onUploadProgress,
          });
          this.data = data;
        } catch (error) {
          throw new Error(error);
        }
      }
    },
    async handleSubmit() {
      if (!this.data) {
        this.$toasted.error("No file selected", { duration: 6000 });
      } else {
        this.progressDisplay = true;
        const payload = {
          userId: this.$AuthUser.id,
          orgId: this.$orgId,
          description: this.description,
          year: this.year.getFullYear(),
          "status": "draft",
          templateId: this.selectedTemplate,

          fileUrl: this.data.fileUrl,
        };
        try {
          const response = await this.$_importAgreement(payload)
              this.$toasted.success(response.data.message, { duration: 5000 });
              this.$router.push({
                name: "OrganisationlGoal",
                query: { activeTab: "Current Goals" },
              });
        }   catch (error) {
              this.$toasted.error(error.response.data.message, {
                duration: 5000,
              });
              this.payload.year = new Date(this.payload.year, 0);
            }
      }
    },


    progressBar(id) {
      const sliderEl = document.getElementById("range2");

      this.$_getProgressBar(id)
        .then((response) => {
          this.progressCount = response.data.progressStats.progress;

          sliderEl.style.background = `linear-gradient(to right, #13B56A ${response.data.progressStats.progress}%, #ccc ${response.data.progressStats.progress}%)`;

          if (this.progressCount === 100) {
            setTimeout(() => {
              this.progressDisplay = false;
              this.$toasted.success("File uploaded successfully", {
                duration: 5000,
              });
              this.$emit("reload-orgGoals");
              this.$emit("close");
              clearTimeout(this.timerId);
            }, 500);
          }
        })
        .catch((err) => err);

      this.timerId = setTimeout(() => {
        this.progressBar(id);
      }, 15000);
    },
  },
};
</script>

<style scoped>
.deleteIcon {
  width: 35.47px;
  height: 34px;
  background: rgba(195, 60, 80, 0.1);
  border-radius: 5px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

#range2 {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 6px;
  background: #ccc;
}

#range2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #13b56a;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

#range2::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #13b56a;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}
</style>
